import React, { useState } from 'react';
import {
  Avatar,
  Checkbox,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  Theme,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { getChatLastMessageFromStorage } from 'views/home/Chat/hooks/utils';
import { OutgoingMessageChatMessageKind, OutgoingMessageMediaWithCaption } from '@engyalo/salesdesk-api-ts-client';
import { stringAvatar } from '../../helpers/stringAvatar';
import { IChatItemProps } from './types';
import {
  Item,
  TitleContainer,
  ItemTime,
  ItemBody,
  LastMessage,
  UnReadBadge,
  Options,
  OptionsContainerChat,
  ItemTitleAllChatsContainer,
  TitleSeparator,
  ClientNameEllipsis,
} from './style';
import useChatItem from './useChatItem';
import MenuOptions from '../MenuOption';
import useEnvironment from '../../hooks/useEnvironments';

const getSentenceColor = (iscurrentchat: boolean, isSelected: boolean, unreadChat: boolean, hasError?: boolean) => {
  if (hasError) {
    return 'error.main';
  }
  if (iscurrentchat || isSelected || unreadChat) {
    return 'neutral.var90';
  }
  return 'neutral.var60';
};

const getIconColor = (unread: boolean, theme: Theme) =>
  unread ? theme.palette.primary.darker : theme.palette.neutral.var90;

const ChatItem: React.FC<IChatItemProps> = (props: IChatItemProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    id,
    message,
    name,
    hasError = false,
    onClick,
    sessionType,
    unreadChat,
    unreadMessages,
    room,
    iscurrentchat,
    tabType,
    hasDiscussion,
  } = props;
  const {
    isSelected,
    isSelectedItems,
    handleChecked,
    anchorEl,
    handleClose,
    handleOptionClick,
    currentOptions,
    checkData,
    currentUserInfo,
    language,
  } = useChatItem({
    sessionType,
    id,
    unreadMessage: unreadChat,
    room,
  });
  const { getUrl } = useEnvironment();
  const baseUrl = getUrl('http');

  const [audioTime, setAudioTime] = useState('');
  const [onTouchStartValue, setOnTouchStartValue] = useState(0);
  const [isLongClick, setIsLongClick] = useState(false);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const getChatName = () => {
    if (tabType === 'allChats') {
      if (sessionType === 'pending') {
        return (
          <ItemTitleAllChatsContainer>
            <ClientNameEllipsis variant="subtitle1" isAgentOrDepartmentName={false} isOnlyClientName={false}>
              {name}
            </ClientNameEllipsis>
            <TitleSeparator>{'>'}</TitleSeparator>
            <ClientNameEllipsis variant="subtitle1" isAgentOrDepartmentName isOnlyClientName={false}>
              {room.department.name}
            </ClientNameEllipsis>
          </ItemTitleAllChatsContainer>
        );
      }
      let username = '';
      if (!room.servedBy && !room.closedBy) {
        username = room.department.name;
      } else {
        username = room.servedBy
          ? room.servedBy.username
          : room.closedBy.username === 'rocket.cat'
          ? 'System'
          : room.closedBy.username;
      }

      return (
        <ItemTitleAllChatsContainer>
          <ClientNameEllipsis isAgentOrDepartmentName={false} isOnlyClientName={false}>
            {name}
          </ClientNameEllipsis>
          <TitleSeparator>{'>'}</TitleSeparator>
          <ClientNameEllipsis isAgentOrDepartmentName isOnlyClientName={false}>
            {username}
          </ClientNameEllipsis>
        </ItemTitleAllChatsContainer>
      );
    }

    return (
      <ClientNameEllipsis isAgentOrDepartmentName={false} isOnlyClientName>
        {name}
      </ClientNameEllipsis>
    );
  };

  const getAttachmentText = () => {
    const attachment = message.attachments && message.attachments.length > 0 ? message.attachments[0] : null;

    if (attachment.audio_type) {
      const audioinfo = new Audio(`${baseUrl}${attachment.audio_url}`);
      audioinfo.preload = 'metadata';

      audioinfo.addEventListener('canplaythrough', (event) => {
        const { duration } = audioinfo;
        if (!isNaN(duration)) {
          let sec = Math.floor(duration);
          const min = Math.floor(sec / 60);
          sec = Math.floor(sec % 60);
          setAudioTime(`(${min}:${sec < 10 ? `0${sec}` : sec})`);
        }
      });
      return (
        <>
          <i
            className="fa-solid fa-microphone"
            style={{
              color: getIconColor(unreadChat, theme),
            }}
          />
          <span>{`${t('audioMessage')} ${audioTime}`}</span>
        </>
      );
    }
    return (
      <>
        <i
          className="fa-solid fa-paperclip"
          style={{
            color: getIconColor(unreadChat, theme),
          }}
        />
        <span>{attachment.title}</span>
      </>
    );
  };

  const getOnHoldText = () => {
    const reason = room?.livechatData && room.livechatData.putInHoldCause ? room.livechatData.putInHoldCause : '';
    const user = message.u._id === currentUserInfo._id ? `${t('you')}` : `${message.u.name}`;
    return (
      <>
        <i
          className="fa-solid fa-microphone"
          style={{
            color: getIconColor(unreadChat, theme),
          }}
        />

        <span>{`${user} ${reason ? `: ${reason}` : t('putChatOnHoldMessage')}`}</span>
      </>
    );
  };

  const getClosedText = () => {
    const closedByBot = room.closedBy && room.closedBy._id === 'rocket.cat';
    const annotation = message.msg !== '' && !message.msg.trim().startsWith('TICKET:#') ? message.msg : '';
    const messageText =
      room.closedBy._id === currentUserInfo._id
        ? `${annotation ? `${t('you')}:  ${annotation}` : `${t('you')} ${t('finishedTheChat')}`}`
        : closedByBot
        ? t('messageChatEndedByInactivity')
        : ` ${annotation ? `${message.u.name}:  ${annotation}` : `${message.u.name}: ${t('finishedTheChat')}`}`;
    return (
      <>
        <i
          className="fa-regular fa-box-check"
          style={{
            color: getIconColor(unreadChat, theme),
          }}
        />
        <span>{messageText}</span>
      </>
    );
  };

  const getAgentSentMessage = () => {
    const lastMessageFromStorage = getChatLastMessageFromStorage(room._id);
    if (lastMessageFromStorage?.chat && lastMessageFromStorage.chat.authorRole === 'agent') {
      const messageKindIconMap: Record<string, string> = {
        [OutgoingMessageChatMessageKind.Image]: 'image',
        [OutgoingMessageChatMessageKind.Video]: 'video',
        [OutgoingMessageChatMessageKind.Document]: 'paperclip',
        [OutgoingMessageChatMessageKind.Audio]: 'microphone',
      };
      const messageKind = lastMessageFromStorage.chat.kind;

      if (messageKind === OutgoingMessageChatMessageKind.Text) {
        message.msg = lastMessageFromStorage.chat?.text?.body ?? '';
      } else if (lastMessageFromStorage?.kind === 'chat' && Object.keys(messageKindIconMap).includes(messageKind)) {
        const iconName = messageKindIconMap[messageKind];
        const messageContent = lastMessageFromStorage.chat?.[messageKind] as OutgoingMessageMediaWithCaption;
        return (
          <>
            <span>{`${t('you')}: `}</span>
            <i
              className={`fa-solid fa-${iconName}`}
              style={{
                color: getIconColor(false, theme),
              }}
            />
            <span>
              {messageKind !== OutgoingMessageChatMessageKind.Audio ? messageContent?.caption ?? '' : t('audioMessage')}
            </span>
          </>
        );
      }
    }
    return undefined;
  };

  const getTextMessage = () => {
    const DEFAULT_MESSAGE = `[${t('sidebar.openForDetails')}]`;
    const isCurrentUser = message.u._id === currentUserInfo._id;

    const messageBody = message.msg || DEFAULT_MESSAGE;

    const conditions: Array<[boolean, string]> = [
      [isCurrentUser, `${t('you')}:`],
      [message.u.name?.includes('Yalo Bot Passthrough'), `${t('flow')}:`],
      [!message.u.username?.startsWith('whatsapp'), `${message.u.name}:`],
    ];

    const [, messagePrefix = ''] = conditions.find(([check]) => check) || [];

    if (!room.open && room.closedAt) {
      return getClosedText();
    }
    if (room.onHold) {
      return getOnHoldText();
    }
    if (!message.msg && message.attachments && message.attachments.length > 0) {
      return getAttachmentText();
    }
    if (message.t === 'discussion-created') {
      return <span>{`${t('discussionCreated')}`}</span>;
    }
    if (message.t === 'livechat_transfer_history') {
      return <span>{`${messagePrefix} ${t('willBeAnsweredSoon')}`}</span>;
    }
    if (message.msg === '' && isCurrentUser) {
      const agentMessage = getAgentSentMessage();

      if (agentMessage) {
        return agentMessage;
      }
    }

    return <span>{`${messagePrefix} ${messageBody}`}</span>;
  };

  return (
    <Item
      data-testid="itemChat"
      id="itemChat"
      isCurrentChat={iscurrentchat}
      isSelected={isSelectedItems}
      disablePadding
      key={id}
      alignItems="flex-start"
      onTouchStart={(event) => setOnTouchStartValue(event.timeStamp)}
      onTouchEnd={(event) => {
        const isLongClick = event.timeStamp - onTouchStartValue;
        if (isLongClick > 1000) {
          setOnTouchStartValue(0);
          setIsLongClick(true);
        }
      }}
      onClick={() => {
        !isMobile ? onClick() : {};
      }}
    >
      {isSelectedItems ? (
        <ListItemIcon>
          <Checkbox
            edge="end"
            onChange={() => handleChecked()}
            checked={isSelected}
            disableRipple
            sx={{
              color: 'neutral.var30',
              '&.Mui-checked': {
                color: 'primary.main',
              },
              '& .MuiSvgIcon-root': {
                fontSize: 16,
              },
            }}
          />
        </ListItemIcon>
      ) : (
        <ListItemAvatar onClick={onClick}>
          <Avatar {...stringAvatar(name)} aria-label={t('contactAvatar')} src="" />
        </ListItemAvatar>
      )}

      <ListItemText
        primary={
          <TitleContainer onClick={onClick}>
            <Typography
              color={getSentenceColor(iscurrentchat, isSelected, unreadChat)}
              variant="subtitle1"
              role="ChatNameText"
              variantMapping={{ subtitle1: 'span' }}
            >
              {getChatName()}
            </Typography>
            <ItemTime
              data-testid="timeText"
              color={getSentenceColor(iscurrentchat, isSelected, unreadChat)}
              variant="overline"
            >
              {checkData(message.ts) ? '' : `${new Date(message.ts).toLocaleDateString()} `}

              {language
                ? new Date(message.ts).toLocaleTimeString(language, {
                    hour: '2-digit',
                    minute: '2-digit',
                  })
                : null}
            </ItemTime>
          </TitleContainer>
        }
        secondary={
          <ItemBody>
            <LastMessage
              variant="body1"
              color={getSentenceColor(iscurrentchat, isSelected, unreadChat, hasError)}
              role="menuitem"
              noWrap
            >
              {getTextMessage()}
            </LastMessage>
            <OptionsContainerChat role="optionsContainer">
              {hasError && (
                <i
                  className="fa-solid fa-triangle-exclamation"
                  style={{
                    color: theme.palette.error.main,
                  }}
                />
              )}

              {hasDiscussion &&
                (sessionType === 'inAttendance' || sessionType === 'closedToday' || sessionType === 'onHold') && (
                  <i
                    className="fa-solid fa-screen-users fa-sm"
                    style={{
                      color: theme.palette.neutral.var90,
                      marginRight: '6px',
                    }}
                  />
                )}
              {unreadChat && !hasError && sessionType === 'inAttendance' && (
                <UnReadBadge>
                  {unreadMessages > 0 && unreadMessages <= 99 ? unreadMessages : unreadMessages > 99 ? '99+' : ''}
                </UnReadBadge>
              )}
              {currentOptions && (
                <>
                  <Options
                    data-testid="chevronOptionId"
                    islongclick={isLongClick ? 1 : 0}
                    onClick={(event: React.MouseEvent<HTMLDivElement>) => handleOptionClick(event)}
                  >
                    <i
                      className="fa-solid fa-chevron-down fa-2xs"
                      style={{
                        color: theme.palette.neutral.var70,
                      }}
                    />
                  </Options>
                  <MenuOptions
                    options={currentOptions}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    position="bottom"
                  />
                </>
              )}
            </OptionsContainerChat>
          </ItemBody>
        }
        secondaryTypographyProps={{ component: 'div' }}
      />
    </Item>
  );
};

export default ChatItem;
